import classNames from 'classnames';
import NextLink, { type LinkProps } from 'next/link';
import type { HTMLAttributes, PropsWithChildren } from 'react';

export const Link = (
  props: PropsWithChildren<
    LinkProps & {
      disabled?: boolean;
    } & HTMLAttributes<HTMLAnchorElement>
  >
) => {
  const { disabled, children } = props;
  return (
    <NextLink
      {...props}
      className={classNames(props.className ? props.className : '', {
        'pointer-events-none': disabled,
      })}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    >
      {children}
    </NextLink>
  );
};
