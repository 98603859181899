import { BackupStatus } from '@repo/api-gw-sdk';

import { createBackupStatusNotInCondition } from '@/components/backupStatus/createBackupStatusNotInCondition';
import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import { createCombineCondition, type Condition } from '@/types/advanceFilter';

export const createInventoryLink = (conditions: Condition[]) => {
  const extendedConditions = createCombineCondition([
    ...conditions,
    createBackupStatusNotInCondition([
      BackupStatus.ExcludedFromBackup,
      BackupStatus.Disconnected,
    ]),
  ]);
  return `/inventory?query=${toJsonText(extendedConditions)}`;
};
