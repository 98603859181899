import {
  Button,
  CardContent,
  Divider,
  Stack,
  type SxProps,
} from '@mui/material';
import type { PropsWithChildren } from 'react';

import { WizardStepper } from './wizardStepper';

type StepContainerProps =
  | PropsWithChildren<{
      onBackClick: () => void;
      onNextClick: () => void;
      canGoNext: () => boolean;
      nextButtonText?: string;
      backButtonText?: string;
      hideNavigation?: false;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex?: number;
      sx?: SxProps;
      navigationComponent?: React.ReactNode;
    }>
  | PropsWithChildren<{
      hideNavigation: true;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex: number;
      sx?: SxProps;
    }>;

export const StepContainer = (props: StepContainerProps) => {
  return (
    <Stack className='h-full'>
      <Stack className='overflow-y-auto' flexGrow={1}>
        {!props.hideStepper && (
          <>
            <WizardStepper
              activeStep={props.stepperIndex ?? 0}
              stepsLabels={props.stepperLabels ?? []}
            />
            <Divider />
          </>
        )}
        <CardContent
          className='flex-grow'
          sx={{
            padding: '40px',
            ...props.sx,
          }}
        >
          {props.children}
        </CardContent>
      </Stack>
      {!props.hideNavigation && (
        <Stack
          direction='row'
          className='justify-between items-center px-[40px] py-[16px]'
          sx={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)' }}
        >
          <div>{props.navigationComponent}</div>
          <div>
            <Button
              size='small'
              variant='outlined'
              onClick={props.onBackClick}
              className='mr-[12px]'
              data-testid='wizard-step-back-btn'
            >
              {props.backButtonText || 'Back'}
            </Button>
            <Button
              size='small'
              variant='contained'
              disabled={!props.canGoNext()}
              onClick={props.onNextClick}
              data-testid='wizard-step-next-btn'
            >
              {props.nextButtonText || 'Next'}
            </Button>
          </div>
        </Stack>
      )}
    </Stack>
  );
};
