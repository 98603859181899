import {
  TableContainer,
  Table,
  TableRow,
  styled,
  TableCell,
} from '@mui/material';
import type { BackupPolicy, BackupVault } from '@repo/api-gw-sdk';

import VaultTag from '@/components/vaults/VaultTag';

import { frequencyCronText, retentionText } from './BackupPolicyFormatting';

const PolicyTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.text.secondary,
  padding: '2px 10px 2px 0px',
}));

const PolicyTableHeaderCell = styled(TableCell)(() => ({
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  border: 'none',
  padding: 0,
  paddingBottom: '12px',
}));

export interface BackupPolicyRetentionSchedulesTableProps {
  policy: BackupPolicy;
  vaults?: BackupVault[];
}

export default function BackupPolicyRetentionSchedulesTable(
  props: BackupPolicyRetentionSchedulesTableProps
) {
  return (
    <TableContainer>
      <Table>
        <thead>
          <TableRow>
            <PolicyTableHeaderCell>Frequency</PolicyTableHeaderCell>
            <PolicyTableHeaderCell>Retention</PolicyTableHeaderCell>
            <PolicyTableHeaderCell>Vault</PolicyTableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {props.policy.schedules.map((schedule) => {
            const vault = props.vaults?.find((v) => v.id === schedule.vaultId);
            return (
              <TableRow key={schedule.id}>
                <PolicyTableCell>
                  {schedule.windows && frequencyCronText(schedule.windows)}
                </PolicyTableCell>
                <PolicyTableCell>
                  {schedule && retentionText(schedule.backupRetention)}
                  {!schedule && '--'}
                </PolicyTableCell>
                <PolicyTableCell>
                  {vault && <VaultTag vault={vault} />}
                </PolicyTableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}
