import { IconButton, Stack, styled } from '@mui/material';

export interface BackupPolicyManualRuleProps {
  resourceId: string;
  onDelete: (resourceId: string) => void;
}

const ItemContainer = styled('li')({
  '& button': {
    display: 'none',
  },
  '&:hover button': { display: 'flex' },
  '& i, & button': {
    fontSize: '18px',
  },
});

export const BackupPolicyManualRules = styled('ul')({
  paddingLeft: '24px',
  listStyle: 'circle',
});

export default function BackupPolicyManualRule(
  props: BackupPolicyManualRuleProps
) {
  return (
    <ItemContainer>
      <Stack direction='row' alignItems='center' gap='8px' height='24px'>
        <span>Resource id: {props.resourceId}</span>
        <IconButton onClick={() => props.onDelete(props.resourceId)}>
          <i className='material-symbols-close-rounded' />
        </IconButton>
      </Stack>
    </ItemContainer>
  );
}
