import {
  Step,
  StepLabel,
  Stepper,
  styled,
  type StepIconProps,
} from '@mui/material';

export const WizardStepper = ({
  activeStep,
  stepsLabels,
}: {
  activeStep: number;
  stepsLabels: string[];
}) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className='w-1/2 my-[24px] mx-auto'
    >
      {stepsLabels.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={CircleStepIcon}
            slotProps={{
              label: {
                className: 'text-[12px]',
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const CircleStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  display: 'flex',
  height: 24,
  zIndex: 1,
  alignItems: 'center',

  '& .CircleStepIcon-circle': {
    width: 12,
    height: 12,
    border: '1px solid var(--mui-palette-primary-main)',
    borderRadius: '50%',
    backgroundColor: 'white',
    ...((ownerState.completed || ownerState.active) && {
      backgroundColor: 'var(--mui-palette-primary-main)',
    }),
  },
}));

function CircleStepIcon(props: StepIconProps) {
  const { completed, className, active } = props;

  return (
    <CircleStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <div className='CircleStepIcon-circle' />
    </CircleStepIconRoot>
  );
}
